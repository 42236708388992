<template>
    <div>
        <b-container>
            <b-row
                cols="5"
                align-content="between"
            >
                <b-col cols="12" md="9">
                    <h3>Suspend Editor</h3>
                </b-col>
                <b-col cols="12" md="3">
                    <admin-device-menu></admin-device-menu>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p>{{devCustomer}} - {{devId}}</p>
                </b-col>
            </b-row>
        </b-container>
        <hr>
        <b-button @click="addSuspend" variant="outline-success">
            <b-icon icon="calendar-plus"></b-icon> Add
        </b-button>
        <p></p>
        <div class="text-center mb-3"
             style="max-width: 60rem"
        >
            <b-table striped hover responsive="true" :items="items" :fields="fields">
                <template #cell(status)="row">
                    <b-icon-cloud-check v-if="items[row.index].stat"></b-icon-cloud-check>
                    <b-icon-cloud-slash v-else></b-icon-cloud-slash>
                </template>
                <template #cell(edit)="row">
                    <b-icon-pencil-fill @click="editItem(row.index)"></b-icon-pencil-fill>
                </template>
                <template #cell(delete)="row">
                    <b-icon-trash-fill @click="delItem(row.index)"></b-icon-trash-fill>
                </template>
            </b-table>
            <hr>
            <b-button @click="saveSuspend" variant="outline-primary">
                <b-icon icon="cloud-arrow-up"></b-icon> Save
            </b-button>
        </div>
        <b-modal
            id="edit-sus"
            ref="modal"
            centered
            style="min-width: 30rem"
            :title="addEditTitle"
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <template #modal-footer>
                <b-container>
                    <b-row cols="5">
                        <b-col cols=""></b-col>
                        <b-button
                            variant="success"
                            @click="handleEditOk"
                        >
                            Save
                        </b-button>
                        <b-col></b-col>
                        <b-button
                            variant="danger"
                            @click="handleEditCancel"
                        >
                            Cancel
                        </b-button>
                    </b-row>
                </b-container>
            </template>
            <b-form ref="form" class="text-center" @submit.stop.prevent="handleEditSubmit">
                <b-form-group>
                    <p>Select Range</p>
                    <date-picker
                        v-model="range"
                        mode="dateTime"
                        :masks="masks"
                        is-range
                    >
                    </date-picker>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import AdminDeviceMenu from "@/components/AdminDeviceMenu";

export default {
    name: "AdminSusSchedule",
    components: {
        AdminDeviceMenu
    },
    data() {
        return {
            devId: null,
            items: [],
            fields: [
                'status',
                'fromDate',
                'fromTime',
                'toDate',
                'toTime',
                'edit',
                'delete'
            ],
            selItem: 0,
            addEdit: 0,
            addEditTitle: "Add",
            susRef: null,
            range: {
                start: new Date(),
                end: new Date(),
            },
            masks: {
                input: 'MM/DD/YYYY h:mm A',
            },
        }
    },
    created() {
        this.devId = this.$route.params.id;
        this.$store.dispatch('getAdminDeviceGeo', {
            id: this.devId,
            userId: this.$store.getters.adminSelectedDev.userId
        });
        this.$store.dispatch('getDeviceSysOther', {
            devId: this.devId,
            userId: this.$store.getters.adminSelectedDev.userId
        });
        this.$store.dispatch('getDeviceSuspendOther', {
            id: this.devId,
            userId: this.$store.getters.adminSelectedDev.userId
        }).then(() => {
            this.$store.dispatch('getDeviceCfgOther', {
                devId: this.devId,
                userId: this.$store.getters.adminSelectedDev.userId
            }).then(() => {
                this.susRef = this.$store.getters.deviceSus.slice();
                this.updateSus();
            });
        });

    },
    computed: {
        devCustomer () {
            if (this.$store.getters.deviceSys === null)
                return "";
            else
                return this.$store.getters.deviceSys.sysName;
        },
    },
    methods: {
        updateSus() {
            this.items = []
            let sus = this.susRef;
            for (let i = 0; i < sus.length; i++) {
                let sItem = {};
                if (i % 2 === 0) {
                    sItem._rowVariant = 'danger';
                }
                // else {
                //     sItem._rowVariant = 'secondary';
                // }
                sItem.fromDate = this.moment(this.getLocalOffset(sus[i].sTime, sus[i].gmtOffset) * 1000).format("M/DD/YYYY");
                sItem.fromTime = this.moment(this.getLocalOffset(sus[i].sTime, sus[i].gmtOffset) * 1000).format("h:mm A");
                sItem.toDate = this.moment(this.getLocalOffset(sus[i].eTime, sus[i].gmtOffset) * 1000).format("M/DD/YYYY");
                sItem.toTime = this.moment(this.getLocalOffset(sus[i].eTime, sus[i].gmtOffset) * 1000).format("h:mm A");

                // eslint-disable-next-line no-prototype-builtins
                if (sus[i].hasOwnProperty('stat')) {
                    sItem.stat = sus[i].stat;
                } else {
                    sItem.stat = true;
                }

                this.items.push(sItem);
            }
        },
        getLocalOffset(timestamp, altOffset) {
            const geoData = this.$store.getters.adminSelectedGeo;
            let totalOffset = geoData.gmtOffset;
            let orgDst = this.moment(timestamp * 1000).isDST();
            let curDst = this.moment().isDST();
            let useLocal = false;
            if (Math.abs(Math.abs(altOffset) - Math.abs(geoData.gmtOffset)) > 1) {
                altOffset = totalOffset;
                useLocal = true;
            }
            if (altOffset !== 0) {
                if (altOffset !== geoData.gmtOffset) {
                    totalOffset = geoData.gmtOffset - (geoData.gmtOffset - altOffset);
                }
            }
            if (orgDst !== curDst) {
                if (!orgDst && curDst)
                    totalOffset += 1;
                else if (orgDst && !curDst)
                    totalOffset -= 1;
            }
            if (useLocal) {
                timestamp += ((totalOffset * 60) * 60);
                let mn = this.moment(timestamp * 1000);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
            else {
                let mn = this.moment(timestamp * 1000).utcOffset(totalOffset * 60);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
        },
        editItem(itemIndex) {
            this.selItem = itemIndex;
            this.addEdit = 1;
            this.addEditTitle = "Edit";
            const curSus = this.susRef[this.selItem];
            const mStart = this.moment(curSus.sTime * 1000);
            const mEnd = this.moment(curSus.eTime * 1000);
            this.range.start = new Date(mStart.year(), mStart.month(), mStart.date(), mStart.hours(), mStart.minutes());
            this.range.end = new Date(mEnd.year(), mEnd.month(), mEnd.date(), mEnd.hours(), mEnd.minutes());
            this.$refs['modal'].show();
        },
        delItem(itemIndex) {
            this.susRef.splice(itemIndex, 1);
            this.updateSus();
        },
        addSuspend() {
            this.addEdit = 0;
            this.addEditTitle = "Add";
            const mStart = this.moment();
            const mEnd = this.moment().add(1, 'd');
            this.range.start = new Date(mStart.year(), mStart.month(), mStart.date(), 6, 30);
            this.range.end = new Date(mEnd.year(), mEnd.month(), mEnd.date(), 18, 30);
            this.$refs['modal'].show();
        },
        saveSuspend() {
            let sus = [];
            let changeCount = 0;
            for (let i = 0; i < this.susRef.length; i++) {
                sus.push({
                    sTime: this.susRef[i].sTime,
                    eTime: this.susRef[i].eTime,
                    gmtOffset: this.susRef[i].gmtOffset
                });
                if (this.susRef[i].stat === false) {
                    changeCount++;
                }
            }
            if (changeCount > 0 || this.susRef.length !== this.$store.getters.deviceSus.length) {
                this.$store.dispatch('setDeviceSuspendOther', {
                    id: this.devId,
                    userId: this.$store.getters.adminSelectedDev.userId,
                    sus: sus
                }).then(() => {
                    this.$store.commit('storeDeviceSus', sus);
                    this.$router.back();
                });
            } else {
                this.$router.back();
            }
        },
        resetEditModal() {

        },
        // eslint-disable-next-line no-unused-vars
        handleEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleEditSubmit();
        },
        // eslint-disable-next-line no-unused-vars
        handleEditCancel(bvModalEvt) {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sus')
            });
        },
        handleEditSubmit() {
            const sysGmtOffset = this.$store.getters.adminSelectedGeo.gmtOffset;
            let editIdx = this.selItem;
            if (this.addEdit === 0) { // Add new Entry
                let newEntry = {
                    sTime: Date.now() / 1000,
                    eTime: Date.now() / 1000,
                    gmtOffset: sysGmtOffset,
                }
                this.susRef.push(newEntry);
                editIdx = this.susRef.length - 1;
            }
            this.susRef[editIdx].stat = false;
            this.susRef[editIdx].gmtOffset = sysGmtOffset;

            this.susRef[editIdx].sTime = parseInt(this.moment(this.range.start).format('X'));
            this.susRef[editIdx].eTime = parseInt(this.moment(this.range.end).format('X'));

            this.updateSus();

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sus')
            });
        }
    }
}
</script>

<style scoped>

</style>
